<template>
    <v-container fluid>
      <v-row style="margin-bottom: 100px">
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-col cols="12" xs="12" sm="5" md="4">
            <v-card class="pa-md-3">  
                <h4 class="m-0">Total Employee</h4>

                <div id="employeeTotal" style="height: 392px; width: 100%;"></div>
            </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="5" md="8">
            <v-card class="pa-md-3">
                <h4 class="m-0">Employee Type and Stat</h4>

                <div id="monthlyStat" style="height: 185px; width: 100%;"></div>
                <br>
                <div id="dailyStat" style="height: 185px; width: 100%;"></div>
            </v-card>
            
        </v-col>
        <v-col cols="12">
            <v-card class="pa-md-3">
                <h4 class="m-0">Employee by Branch Office</h4>

                <div id="employeeBranchOffice" style="height: 370px; width: 100%;"></div>

            </v-card>
            
        </v-col>
        <v-col cols="12">
            <v-card class="pa-md-3">
                <h4 class="m-0">Absent by Type (YTD)</h4>

                <div id="absentByType" style="height: 370px; width: 100%;"></div>

            </v-card>
            
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {
                breadcumbs: [
                    {
                    text: 'Starcon',
                    disabled: false,
                    href: '/admin/sms',
                    },
                    {
                    text: 'HRIS',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Overview',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
            }
        },
        mounted(){
            this.getData()
        },
        methods:{
            async getData(){
                await axios.get(`${process.env.VUE_APP_URL}/api/starcon/hris`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    console.log(res.data);

                    // employeeTotal Chart

                        if (res.data.total_employee.length > 0) { 

                            var dp = [];

                            for (var i = 0; i < res.data.total_employee.length; i++) {

                                dp.push({ label: res.data.total_employee[i].emp_type, y: parseFloat(res.data.total_employee[i].jml), legendText: res.data.total_employee[i].emp_type });
                            }

                            this.getPieChart(res.data.all_total+' Employees', res.data.result1_caption, dp, 'employeeTotal')
                        }

                        else if (res.data.total_employee.length < 1 ) {

                            var dp = [];
                            dp.push({ y: 0 });
                            this.getPieChart(res.data.all_total+' Employees', res.data.result1_caption, dp, 'employeeTotal')

                        }

                    //EmployeeByStat

                        if (res.data.count_emp_monthly.length > 0) { 

                            var dp = [];

                            for (var i = 0; i < res.data.count_emp_monthly.length; i++) {

                                dp.push({ label: res.data.count_emp_monthly[i].emp_stat, y: parseFloat(res.data.count_emp_monthly[i].jml) });
                            }

                            this.getChart1('Monthly ('+res.data.total_emp_monthly+' Employee)', res.data.result1_caption, dp, 'bar', 'monthlyStat', '', 'TotalEmp', '#185ADB', 'Count Emp by Stat')
                        }

                        else if (res.data.count_emp_monthly.length < 1 ) {

                            var dp = [];
                            dp.push({ y: 0 });
                            this.getChart1('Monthly ('+res.data.total_emp_monthly+' Employee)', res.data.result1_caption, dp, 'bar', 'monthlyStat', '', 'TotalEmp', '#185ADB', 'Count Emp by Stat')
                        }

                        if (res.data.count_emp_daily.length > 0) { 
                            console.log(res.data.count_emp_daily);
                            var dp = [];

                            for (var i = 0; i < res.data.count_emp_daily.length; i++) {

                                dp.push({ label: res.data.count_emp_daily[i].emp_stat, y: parseFloat(res.data.count_emp_daily[i].jml) });
                            }

                            this.getChart1('Daily ('+res.data.total_emp_daily+' Employee)', res.data.result1_caption, dp, 'bar', 'dailyStat', '', 'TotalEmp', '#01937C', 'Count Emp by Stat')
                        }

                        else if (res.data.count_emp_daily.length < 1 ) {

                            var dp = [];
                            dp.push({ y: 0 });
                            this.getChart1('Daily ('+res.data.total_emp_daily+' Employee)', res.data.result1_caption, dp, 'bar', 'dailyStat', '', 'TotalEmp', '#01937C', 'Count Emp by Stat')
                        }

                    //EmployeeByOffice

                        if (res.data.count_emp_office.length > 0) { 

                                var dp = [];

                                for (var i = 0; i < res.data.count_emp_office.length; i++) {

                                    dp.push({ label: res.data.count_emp_office[i].office_name, y: parseFloat(res.data.count_emp_office[i].jml) });
                                }

                                this.getChart1('', res.data.result1_caption, dp, 'column', 'employeeBranchOffice', '', 'TotalEmp', '#C84B31', 'Count Emp by BranchOffice')
                        }

                        else if (res.data.count_emp_office.length < 1 ) {

                                var dp = [];
                                dp.push({ y: 0 });
                                this.getChart1('', res.data.result1_caption, dp, 'column', 'employeeBranchOffice', '', 'TotalEmp', '#C84B31', 'Count Emp by BranchOffice')
                        }
                    
                    //EmployeeAbsent

                        if (res.data.count_emp_absent.length > 0) { 

                                var dp = [];

                                for (var i = 0; i < res.data.count_emp_absent.length; i++) {

                                    dp.push({ label: res.data.count_emp_absent[i].keterangan, y: parseFloat(res.data.count_emp_absent[i].jml) });
                                }

                                this.getChart1('', res.data.result1_caption, dp, 'column', 'absentByType', '', 'TotalAbsent', '#D83A56', 'Absent by Type')
                        }

                        else if (res.data.count_emp_absent.length < 1 ) {

                                var dp = [];
                                dp.push({ y: 0 });
                                this.getChart1('', res.data.result1_caption, dp, 'column', 'absentByType', '', 'TotalAbsent', '#D83A56', 'Absent by Type')
                        }


                });
            },
            getPieChart(title, label, dp, container){
                var x = window.matchMedia("(max-width: 991px)")

                var pie_chart = new CanvasJS.Chart(container, {
                                    animationEnabled: true,
                                    title: {
                                        text: title,
                                        fontFamily: "Calibri",
                                        fontSize: 20
                                    },
                                    subtitles:[
                                        {
                                            text: label,
                                            fontFamily: "Calibri",
                                            fontColor: "red",
                                            fontSize: 12
                                        }
                                    ],
                                    exportEnabled: true,
                                    theme: "light2",
                                    exportEnabled: true,
                                    legend: {
                                        itemclick: this.toggleDataPointVisibility
                                    },
                                    data: [{

                                        type: "pie",
                                        // click: onClick1,
                                        percentFormatString: "#0.##",
                                        indexLabel: "{label} #percent%",
                                        indexLabelFontSize: 12,
                                        showInLegend: true

                                    }]
                                });

                pie_chart.options.data[0].dataPoints = dp;
                // showDefaultText(pie_chart, "No Data Found!");

                if (x.matches) {

                    for(var i = 0; i < pie_chart.options.data.length; i++){
                        pie_chart.options.data[i].indexLabelFontSize = 8;
                    }
                    pie_chart.render();
                }
                pie_chart.render();
            },
            toggleDataPointVisibility(e){
                if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                    e.dataPoint.y = e.dataPoint.actualYValue;
                    e.dataPoint.actualYValue = null;
                    e.dataPoint.indexLabelFontSize = null;
                    e.dataPoint.indexLabelLineThickness = null;
                    e.dataPoint.legendMarkerType = "circle";
                } 
                else {
                    e.dataPoint.actualYValue = e.dataPoint.y;
                    e.dataPoint.y = 0;
                    e.dataPoint.indexLabelFontSize = 0;
                    e.dataPoint.indexLabelLineThickness = 0; 
                    e.dataPoint.legendMarkerType = "cross";
                }
                e.chart.render();
            },
            getChart1(title, label, dp, type, container, click, name, color, titleY){
                var x = window.matchMedia("(max-width: 991px)")

                var chart = new CanvasJS.Chart(container, {
                                animationEnabled: true,
                                exportEnabled: true,
                                theme: "light2",
                                title: {
                                    text: title,
                                    fontFamily: "Calibri",
                                    fontSize: 20
                                },
                                subtitles:[
                                    {
                                        text: label,
                                        fontFamily: "Calibri",
                                        fontColor: "red",
                                        fontSize: 12
                                    }
                                ],
                                exportEnabled: true,
                                axisY: {
                                    crosshair: {
                                        enabled: true,
                                        snapToDataPoint: true
                                    },
                                    title: titleY,
                                    labelFormatter: this.addSymbols,
                                },
                                toolTip:{
                                    shared:true
                                },
                                legend: {
                                    cursor: "pointer",
                                    itemclick: this.toggleDataSeries
                                },
                                data: [
                                    {
                                        type: type,
                                        click: click,
                                        name: name,
                                        showInLegend: true,
                                        indexLabel: "{y}",
                                        indexLabelPlacement: "outside",
                                        // indexLabelOrientation: "vertical",
                                        // indexLabelFontColor: "#fff",
                                        indexLabelFontWeight: "bold",
                                        indexLabelFontSize: 12,
                                        indexLabelFontFamily: "calibri",
                                        color: color
                                    }
                                ]
                            });
                chart.options.data[0].dataPoints = dp;
                // showDefaultText(chart, "No Data Found!");

                if (x.matches) {

                    for(var i = 0; i < chart.options.data.length; i++){
                        chart.options.data[i].indexLabelFontSize = 8;
                    }
                    chart.render();
                }
                chart.render();
            },
            addSymbols(e) {
                var suffixes = ["", "K", "M", "B"];
                var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

                if(order > suffixes.length - 1)
                    order = suffixes.length - 1;

                var suffix = suffixes[order];
                return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
            },
            toggleDataSeries(e) {
                if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }
                e.chart.render();
            }
        },
        
        watch: {

        }
    }
</script>